/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import getAppById from "./requests/getAppById";
import "./stepper.css";
import Spinner from "../../components/spinner/Spinner";
import EditApplicationModal from "./EditApplicantModal";
import getAppNotifications from "./requests/getAppNotifications";
import updateApplication from "./updateApplication";
import notify from "../../services/toast";
import { Link } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../constant";
import Logo from "../../assets/NonProfitmed.png";
import SULogo from "../../assets/StartUp.png";
import FileUploader from "../../components/file_uploader/FileUploader";
import Modal from "../../components/modal/Modal";
import ButtonPrimary from "../../components/button/ButtonSecondary";
import SelectInput from "../../components/select_input/SelectInput";
import { format } from "date-fns";
import ApplicationFile from "./ApplicationFile";
import config from "../../config";
import getAppsByUserId from "./requests/getAppsByUserId";
import { filter } from "lodash";
import CommunityPartnerProfile from "./CommunityPartnerProfile";
import updateBanStatus from "./updateBanStatus";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function ApplicantProfile({ }) {
	const [notification, setNotification] = useState("");
	const [showApplicantModal, setShowApplicantModal] = useState(false);
	const [showUploadFile, setShowUploadFile] = useState(false);
	const { userState } = useUserContext();
	const [appApprovedModal, setAppApprovedModal] = useState(false);
	const [appDeniedModal, setAppDeniedModal] = useState(false);
	const [denyReasonModal, setDenyReasonModal] = useState(false);
	const [application, setApplication] = React.useState({});
	const [apps, setApps] = useState("");
	const app = application;
	const params = useParams();
	const navigate = useNavigate();
	const [idFile, setIdFile] = useState("");

	const [isContacted, setIsContacted] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [scoreMemAwarded, setScoreMemAwarded] = useState();
	const [awardedMember, setAwardedMember] = useState();

	const [showMissionTooltip, setShowMissionTooltip] = useState(false);
	const [showFitTooltip, setShowFitTooltip] = useState(false);
	const [showImpactTooltip, setShowImpactTooltip] = useState(false);
	const [showLocationUsageTooltip, setShowLocationUsageTooltip] = useState(false);
	const [showChallengeTooltip, setShowChallengeTooltip] = useState(false);
	const [showFundingTooltip, setShowFundingTooltip] = useState(false);
	const [showEventsTooltip, setShowEventsTooltip] = useState(false);
	const [showVolunteerTooltip, setShowVolunteerTooltip] = useState(false);
	const [showOverallImpactTooltip, setShowOverallImpactTooltip] = useState(false);
	const [showBusinessPlanTooltip, setShowBusinessPlanTooltip] = useState(false);
	const [showBusinessVideoTooltip, setShowBusinessVideoTooltip] = useState(false);
	const [showFundingStrategyTooltip, setShowFundingStrategyTooltip] = useState(false);
	const [showBreakevenPointTooltip, setShowBreakevenPointTooltip] = useState(false);
	const [showPlanHireTooltip, setShowPlanHireTooltip] = useState(false);
	const [showMembersAwardedTooltip, setShowMembersAwardedTooltip] = useState(false);



	const [tabs, setTabs] = useState([
		{ name: "Overview" },
		{ name: "Scorecard" },
		{ name: "Application" },
	]);

	const [tabYears, setTabYears] = useState([
		{ name: "2023" },
		{ name: "2024" },
	]);

	const [selectedTab, setSelectedTab] = useState("Overview");
	const initialState = apps && apps[0].createdAt;

	const [selectedTabYear, setSelectedTabYear] = useState(initialState);

	// const [selectedTabYear, setSelectedTabYear] = useState(apps && apps.length > 0 ? apps[0].createdAt : null);

	const changeTab = (year) => {
		setSelectedTab(year);
	};

	// Get years for applications
	const tabYear = [...new Set(apps ? apps.map((app) => app.createdAt) : null)];
	const filteredApplication = apps
		? apps.filter((app) => app.createdAt === selectedTabYear)
		: null;
	const selectedApp = filteredApplication ? filteredApplication[0] : null;

	const createdAt = selectedApp?.createdAt ? selectedApp?.createdAt : null;

	const onboardingDate = selectedApp?.onboardingDate ? selectedApp?.onboardingDate : null;
	var submissionDate = new Date(onboardingDate);
	function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}

	var newDate = addYears(submissionDate, 1);

	const orgAmountOPTIONS = [
		{ value: 0, label: "0" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const scorecardOPTIONS = [
		{ value: 0, label: "0" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
	];
	const statusOPTIONS = [
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
	];
	const appOPTIONS = [
		{ value: "2023", label: "2023" },
		{ value: "2024", label: "2024" },
	];
	const [appYear, setAppYear] = useState();
	const denyReasons = [
		{ value: "At Capacity", label: "Location is at capacity" },
		{
			value: "Previous Member",
			label: "Applicant is a previous COhatch member",
		},
		{ value: "Current Member", label: "Applicant is a current COhatch member" },
		{ value: "Poor Fit", label: "Applicant was a Poor Fit" },
	];

	const [amount, setAmount] = React.useState("");

	// PAYLOAD
	const handleSaveFile = (payload) => {
		const newPayload = {
			...payload,
			idNumber: {
				idOption: "Yes",
				idFile, // Add the file data here
				idReason: null,
			},
		};
		handleSubmit(newPayload); // Submit the updated payload
		setShowUploadFile(false);
	};

	const handleChange = (key, value) => {
		console.log(key, value);
		setPayload({
			...payload,
			[key]: value,
		});
	};
	const handleUpdate = (payload) => {
		if (payload.mlStatus === "approved") {
			setPayload({
				...payload,
				appStatus: "approved",
			});
			setAppApprovedModal(true);
		} else if (payload.cmStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else if (payload.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	};

	// Updating Application
	const [payload, setPayload] = React.useState({
		// Personal Info
		firstName: selectedApp?.firstName,
		lastName: selectedApp?.lastName,
		email: selectedApp?.email,
		phoneNumber: selectedApp?.phoneNumber,

		// Application Status
		cmStatus: selectedApp?.cmStatus,
		mlStatus: selectedApp?.mlStatus,
		appStatus: selectedApp?.appStatus,
		officeRnd: selectedApp?.officeRnd,
		annualAward: selectedApp?.annualAward,
		denyReason: selectedApp?.denyReason,
		notes: selectedApp?.notes,

		// Scoring - Mission & Impact
		scoreMission: selectedApp?.scoreMission,
		scoreImpact: selectedApp?.scoreImpact,

		// Scoring - Funding & Financials
		scoreFunding: selectedApp?.scoreFunding,
		scoreBreakevenPoint: selectedApp?.scoreBreakevenPoint,

		// Scoring - Volunteers & Events
		scoreVolunteers: selectedApp?.scoreVolunteers,
		scoreEvents: selectedApp?.scoreEvents,

		// Scoring - Challenges & Fit
		scoreChallenges: selectedApp?.scoreChallenges,
		scoreFit: selectedApp?.scoreFit,
		scorePlanHire: selectedApp?.scorePlanHire,

		// Scoring - Business Specific
		scoreBusinessVideo: selectedApp?.scoreBusinessVideo,
		scoreBusinessPlan: selectedApp?.scoreBusinessPlan,
		scoreLocationUsage: selectedApp?.scoreLocationUsage,

		// Scoring - Overall Impact
		scoreOverallAppImpact: selectedApp?.scoreOverallAppImpact,
		scoreMemAwarded: selectedApp?.scoreMemAwarded,

		// Other Info
		awardedMember: selectedApp?.awardedMember,
		market: selectedApp?.market,
		coLocation: selectedApp?.coLocation,
		status501: selectedApp?.status501,
		dateFounded: selectedApp?.dateFounded,
		idNumber: selectedApp?.idNumber,
		applicantContacted: selectedApp?.applicantContacted,
		onboardingDate: selectedApp?.onboardingDate,
	});

	const handleApproveUpdate = () => {
		handleSubmit(payload);
		setAppApprovedModal(false);
		//add confetti
	};
	const handleDenyUpdate = () => {
		setAppDeniedModal(false);
		setDenyReasonModal(true);
		//add confetti
	};
	const handleDenyPayload = () => {
		handleSubmit(payload);
		setDenyReasonModal(false);
	};
	const handleGoBack = () => {
		setAppApprovedModal(false);
		setAppDeniedModal(false);
	};
	const handleAnnualAward = (evt) => {
		const amount = evt.value * 2388;
		const members = evt.value;
		setAmount(amount);
		setScoreMemAwarded(members);
		setAwardedMember(members);
		handleChange("annualAward", amount);
	};

	const handleClick = () => {
		// Toggle the state when the button is clicked
		setContacted({
			...contacted,
			applicantContacted: "Yes",
		});
		setIsModalOpen(true);
	};

	const handleConfirm = () => {
		// Toggle the state and close the modal when confirmed
		setIsContacted(!isContacted);
		setPayload({
			...payload,
			applicantContacted: "Yes",
		});
		handleSubmit(payload);
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		// Close the modal when canceled
		setIsContacted(!isContacted);
		setIsModalOpen(false);
	};

	const backgroundCriteria = {
		requiredSize: null,
		requiredDimensions: { width: 0, height: 0 },
	};

	function PhotoDisplay({ callback }) {
		return (
			<DocumentPlusIcon
				onClick={callback}
				className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
			/>
		);
	}

	const [contacted, setContacted] = useState({
		idNumber: {
			idFile,
		},
		applicantContacted: application.applicantContacted,
	});

	function handleFileClick() {
		let path = `/impact/all_scholarships/file/${application.id}`;
		navigate(path);
	}

	function renderCause(cause, index) {
		return (
			<div
				index={index}
				className="inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
			>
				{cause}
			</div>
		);
	}

	function renderCause(cause, index) {
		return (
			<div
				index={index}
				className="inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
			>
				{cause}
			</div>
		);
	}

	const fetchApplication = async () => {
		try {
			const response = await getAppById(
				userState.accessToken,
				params.applicationId
			);
			if (response.status === "success") {
				console.log(response.data);
				setApplication(response.data);
				fetchApplicationByUserId(userState.accessToken, response.data.userId);
			} else {
				console.log("error");
			}
		} catch (error) { }
	};

	const fetchApplicationByUserId = async (accessToken, userId) => {
		try {
			const response = await getAppsByUserId(accessToken, userId);
			if (response.status === "success") {
				setApps(response.data);
			} else {
			}
		} catch (error) { }
	};

	const fetchNotifications = async () => {
		try {
			const response = await getAppNotifications(
				userState.accessToken,
				params.applicationId
			);

			if (response.status === "success") {
				const { data } = response;

				setNotification(data);
			} else {
			}
		} catch (error) { }
	};

	const handleReload = async () => {
		await fetchApplication();
	};

	const handleIdFileUpload = (result) => {
		setIdFile({
			idFile: result,
		});
		console.log("Updated Payload:", payload);
		//console.log(idFile, "idfile");
	};
	const [loader, setLoader] = useState(false);
	const handleDocumentUpload = async (data) => {
		// console.log(data.target.files[0], "data");
		try {
			setLoader(true);
			const file = data.target.files[0];
			const folder = "501c3_photos";
			const formData = new FormData();
			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("file", file);
			formData.append("name", file.name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/admin/uploads/files`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handleIdFileUpload(jsonResponse.data);
			} else {
				notify(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log(error, "error");
			// console.log("Something went wrong. Please try again later.", "error");
		} finally {
			setLoader(false);
			console.log("done");
		}
	};
	const handleSubmit = async (payload) => {
		try {
			const response = await updateApplication(
				userState.accessToken,
				payload,
				params.applicationId = selectedApp._id,
			);
			if (response.status === "success") {
				console.log(response);
				notify("Application was successfully updated.", "info");
				handleReload();
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			console.log(error);
			notify("Something went wrong. Please try again later.", "error");
		}
	};

	React.useEffect(() => {
		setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
	}, [scoreMemAwarded]);
	React.useEffect(() => {
		setPayload({
			...payload,
			awardedMember: awardedMember,
		});
	}, [awardedMember]);

	React.useEffect(() => {
		setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
	}, [scoreMemAwarded]);
	React.useEffect(() => {
		setPayload({
			...payload,
			awardedMember: awardedMember,
		});
	}, [awardedMember]);

	useEffect(() => {
		fetchApplication();
		fetchNotifications();
	}, []);

	useEffect(() => {
		if (!apps?.length) return;
		setSelectedTabYear(apps[0].createdAt);
	}, [apps]);

	return (
		<>
			{appApprovedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center">
									<h1>
										Are you sure you would like to approve this application?
										Approving this application will notify the applicant that
										they have been rewarded a scholarship.
									</h1>
									<ButtonPrimary
										size="md"
										text="Approve"
										action={handleApproveUpdate}
									/>
									<button
										className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleGoBack}
									>
										Go Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{appDeniedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Are you sure you would like to reject this application?
										Rejecting this application will send an email to the
										applicant informing them that they have not been chosen to
										receive a scholarship.
									</h1>
									<button
										className="w-full cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white bg-red-700 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleDenyUpdate}
									>
										Reject
									</button>
									<button
										className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleGoBack}
									>
										Go Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{denyReasonModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Please select the reason you are rejecting this application.
									</h1>
									<div className=" w-full">
										<div className="w-full mb-5">
											<SelectInput
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
												placeholder="Select Reason"
												options={denyReasons}
												setSelectedValue={(evt) =>
													handleChange("denyReason", evt.value)
												}
											/>
										</div>
										{payload.denyReason && (
											<div className="w-full">
												<button
													className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
													size="md"
													onClick={handleDenyPayload}
												>
													Submit
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}

			{/* Header Section */}

			<>
				<div className="overflow-hidden rounded-lg bg-white shadow">
					<div className="bg-white p-6">
						<div className="sm:flex sm:items-center sm:justify-between">
							<div className="sm:flex sm:space-x-5">
								<div className="flex-shrink-0">
									{selectedApp?.logoImage?.small?.url ? (
										<img
											className="mx-auto h-20 w-20 rounded-full"
											src={selectedApp?.logoImage.small.url}
											alt=""
										/>
									) : selectedApp?.logoImage?.logoImage?.url ? (
										<img
											className="mx-auto h-20 w-20 rounded-full"
											src={selectedApp?.logoImage.logoImage.url}
											alt=""
										/>
									) : (
										<img
											className="mx-auto h-20 w-20 rounded-full"
											src={selectedApp ? Logo : SULogo}
											alt=""
										/>
									)}
								</div>
								<div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
									<h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
										{selectedApp?.firstName} {selectedApp?.lastName}
									</h1>
									{/* Terms Accepted Status */}
									{selectedApp?.termsAccepted ? (
										<div className="mt-2 inline-flex justify-center rounded-md bg-primary-500 px-3 py-1 text-sm font-semibold text-white">
											Terms Accepted
										</div>
									) : (
										<div className="mt-2 inline-flex justify-center rounded-md bg-danger-500 px-3 py-1 text-sm font-semibold text-white">
											Terms Not Accepted
										</div>
									)}
								</div>
							</div>
							<div className="mt-5 flex justify-center sm:mt-0">
								{selectedApp?.appStatus === "pending" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-gray-400/80 px-3 py-2 text-sm font-semibold ">
										<span className="font-black">Pending</span>
									</div>
								) : selectedApp?.appStatus === "rejected" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-red-600/80 px-3 py-2 text-sm font-semibold ">
										Rejected
									</div>
								) : selectedApp?.appStatus === "approved" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-green-400/80 px-3 py-2 text-sm font-semibold ">
										Active
									</div>
								) : null}
							</div>
						</div>
					</div>


					{/* Tabs aligned in a grid like the new example */}
					<div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
						{tabs.map((tab, tabIdx) => (
							<div
								key={tab.name}
								onClick={() => setSelectedTab(tab.name)}
								className={`px-6 py-5 text-center text-sm font-medium cursor-pointer ${tab.name === selectedTab ? "bg-success-800 text-white" : "text-gray-600"} hover:bg-gray-200`}
							>
								<span>{tab.name}</span>
							</div>
						))}
					</div>
				</div>

				{/* Application Year Nav */}
				<section className="mt-4 flex flex-col items-center bg-gray-50 py-6 rounded-lg shadow-lg">
					{/* Tabs */}
					<div className="sm:hidden w-full">
						<label htmlFor="tabs" className="sr-only">
							Select a tab
						</label>
						<select
							id="tabs"
							name="tabs"
							className="mt-4 block w-full max-w-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-500 sm:text-sm transition duration-200 ease-in-out shadow-md hover:shadow-lg"
							onChange={(e) => changeTab(e.target.value)}
						>
							{tabYear.map((year) => (
								<option key={year} value={year}>
									{format(new Date(year), "yyyy")}
								</option>
							))}
						</select>
					</div>

					<div className="hidden sm:block w-full">
						<div className="border-b">
							<nav className="flex justify-center" aria-label="Tabs">
								{tabYear.map((year) => (
									<div
										key={year}
										onClick={() => setSelectedTabYear(year)}
										className={classNames(
											selectedTabYear === year
												? "bg-primary-600 text-white border-primary-600" // Change to primary-600
												: "border-transparent text-gray-600 hover:bg-primary-100 hover:text-primary-600 hover:border-primary-300", // Change to primary colors
											"whitespace-nowrap py-3 px-6 border-b-2 font-medium text-sm cursor-pointer transition duration-300 ease-in-out rounded-lg"
										)}
									>
										{format(new Date(year), "yyyy")}
									</div>
								))}
							</nav>
						</div>
					</div>
				</section>

				{/*Page Nav */}

				<div className="  grid grid-cols-6 gap-4 mb-16">
					{selectedTab === "Overview" && (
						<div className="mt-10 col-span-4">
							<div className="grid grid-cols-1 divide-y">
								<div className="mb-3">
									<h1 className="font-bold text-xl">Causes & Tags</h1>
									<div className="mt-7">
										<div className="mr-2 inline-flex justify-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ">
											<span className="capitalize">
												{selectedApp?.type} Scholar
											</span>
										</div>

										{selectedApp?.causes?.map((cause, index) => {
											return renderCause(cause, index);
										})}
										{selectedApp?.category && (
											<div className="mr-2 inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ">
												<span className="capitalize">
													{selectedApp?.category}
												</span>
											</div>
										)}
									</div>
								</div>
								<div>
									<div>
										<h1 className="mt-3 mb-3 font-bold text-xl">
											About {selectedApp?.orgName}
										</h1>
										<p className="mb-4">{selectedApp?.orgMission}</p>
									</div>
								</div>
								{/* Info Cards */}
							</div>
							<div className="mt-4 mb-15 ">
								<ul role="list" className=" grid grid-cols-2 gap-6 ">
									{selectedApp?.type === "give" && (
										<li
											key={selectedApp?.email}
											className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
										>
											<div className=" w-full items-center justify-between space-x-6 p-6">
												<div className=" ">
													<div className="flex items-center space-x-3 divide-y divide-gray-200">
														<h3 className=" text-sm font-medium text-primary-500">
															501C3 Document
														</h3>
													</div>

													<div>
														{selectedApp?.idNumber.idReason === "No" && (
															<>
																<h3 className=" text-sm font-medium ">
																	Reason organization does not have a 501C3:
																</h3>
																<p className="mt-1 text-sm text-gray-500">
																	{selectedApp?.idNumber.idReason}
																</p>
															</>
														)}

														{showUploadFile && (
															<Modal>
																<div className="fixed z-10 inset-0 overflow-y-auto">
																	<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
																		{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
																		<span
																			className="hidden sm:inline-block sm:align-middle sm:h-screen"
																			aria-hidden="true"
																		>
																			&#8203;
																		</span>
																		<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
																			<div className="w-full flex justify-between items-center">
																				<div>
																					<button
																						className="cursor-pointer"
																						onClick={() =>
																							setShowUploadFile(false)
																						}
																					>
																						<span className="material-icons-outlined text-4xl">
																							chevron_left
																						</span>
																					</button>
																					<p className="mb-3">
																						Upload Document
																					</p>
																					<input
																						onChange={handleDocumentUpload}
																						type="file"
																						id="giveDocument"
																					/>
																				</div>
																				<div>
																					<button
																						className=" relative rounded shadow-sm px-1.5 py-1.5 text-white  bg-success-800"
																						onClick={() => {
																							handleSaveFile(payload);
																						}}
																						disabled={!(loader === false)}
																					>
																						{loader ? (
																							<span>Uploading..</span>
																						) : (
																							<span>Save File</span>
																						)}
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</Modal>
														)}
													</div>
												</div>
											</div>

											<div className="pl-2 pr-4 flex justify-between">
												<div className="">
													<button
														className="cursor-pointer px-1 py-1  "
														size="md"
														onClick={() => setShowUploadFile(true)}
													>
														Upload
													</button>
												</div>
												<div className="px-1 py-1 ">
													{selectedApp?.idNumber.idFile?.large?.url ? (
														<div className="justify-end">
															<Link
																to={`/impact/501file/${selectedApp?.id}`}
																target="_blank"
															>
																View
															</Link>
														</div>
													) : selectedApp?.idNumber.idFile?.idFile?.url ? (
														<Link
															to={selectedApp?.idNumber.idFile.idFile.url}
															target="_blank"
														>
															Download
														</Link>
													) : null}
												</div>
											</div>
										</li>
									)}
									{selectedApp?.type === "boost" &&
										selectedApp?.businessPdf ? (
										<li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
											<div className="flex w-full items-center justify-between space-x-6 p-6">
												<div className="flex-1 truncate">
													<div className="flex items-center space-x-3">
														<h3 className="truncate text-sm font-medium text-primary-500">
															Proof of Business
														</h3>
													</div>
												</div>
											</div>
											<div className="flex justify-end pr-4">
												<Link
													to={selectedApp?.businessPdf.businessPdf.url}
													target="_blank"
												>
													Download
												</Link>
											</div>
										</li>
									) : null}
									{/* <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
											<div className="flex w-full items-center justify-between space-x-6 p-6">
												<div className="flex-1 truncate">
													<div className="flex items-center space-x-3">
														<h3 className="truncate text-sm font-medium text-primary-500">
														
														</h3>
													</div>
													
												</div>
											</div>
											<div className="flex justify-end pr-4">
												<Link to={`/file/${selectedApp?.id}`} target="_blank">
													View
												</Link>
											</div>
										</li> */}
								</ul>
							</div>
							<div className="mt-10 mb-10">
								<h1 className="font-bold text-black mb-4">
									Impact Stories from {selectedApp?.orgName}
								</h1>
								<p>No Submitted Stories</p>
							</div>
						</div>
					)}

					{/* SCORECARD */}
					{selectedTab === "Scorecard" && (
						<div className="mt-10 col-span-4 w-4/3">
							<div className="">
								<h1 className="mb-6 font-bold text-2xl text-gray-800">Scorecard</h1>

								{selectedApp?.type === "give" && (
									<>
										<div className="mt-6 mb-4 w-full space-y-6 bg-gray-50 p-6 rounded-lg shadow-md">
											{/* Business Video*/}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm mb-7">
												<label
													htmlFor="businessVideo"
													className="text-lg font-semibold text-gray-800 block mb-2"
												>
													Record a Business Video (3 minutes or less)
												</label>
												<div className="mt-1">
													{selectedApp?.businessVideo && selectedApp?.businessVideo !== "N/A" ? (
														<Link
															className="underline text-blue-600 hover:text-blue-800 transition"
															to={selectedApp.businessVideo}
															target="_blank"
														>
															{selectedApp.businessVideo}
														</Link>
													) : (
														<span className="text-gray-600">
															No business video available for this application.
														</span>
													)}
												</div>
												<label
													htmlFor="scoreBusinessVideo"
													className="block text-md font-semibold text-gray-800 mb-2 relative mt-4"
												>
													Business Video Score (0 OR 3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowBusinessVideoTooltip(true)}
													>
														?
													</button>
													{showBusinessVideoTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowBusinessVideoTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">
																Business Video Score Guide:
															</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "No video submitted.",
																	},
																	{
																		score: 3,
																		text: "Thorough video presenting a clear narrative of the business.",
																	},
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS.filter(
														(option) => option.value === 0 || option.value === 3
													)} // Filter to show only 0 and 3
													placeholder={
														selectedApp?.scoreBusinessVideo != null
															? selectedApp.scoreBusinessVideo
															: "Select a score"
													}
													defaultValue={
														selectedApp?.scoreBusinessVideo != null
															? selectedApp.scoreBusinessVideo
															: undefined
													}
													setSelectedValue={(evt) => handleChange("scoreBusinessVideo", evt.value)}
												/>
											</div>


											{/* Organization Mission */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="orgMission"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Description of Your Organization and Mission:
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.orgMission}
													rows="4"
												/>
												<label htmlFor="scoreMission" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Clear Mission (1-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowMissionTooltip(true)}
													>
														?
													</button>
													{showMissionTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowMissionTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Clear Mission Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-2">
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">0 - </span>
																	<p>
																		<strong>Clarity:</strong> Vision and mission statements are vague or absent.
																	</p>
																	<p>
																		<strong>Alignment:</strong> There is no clear connection between the organization's activities and the vision or mission.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">1 - </span>
																	<p>
																		<strong>Clarity:</strong> Vision and mission statements are somewhat unclear or lacking in specific details.
																	</p>
																	<p>
																		<strong>Alignment:</strong> There is a limited connection between the organization's activities and the vision or mission.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">2 - </span>
																	<p>
																		<strong>Clarity:</strong> Vision and mission statements are clear and understandable.
																	</p>
																	<p>
																		<strong>Alignment:</strong> There is a general connection between the organization's activities and the vision or mission.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">3 - </span>
																	<p>
																		<strong>Clarity:</strong> Vision and mission statements are clear, concise, and specific.
																	</p>
																	<p>
																		<strong>Alignment:</strong> There is a strong connection between the organization's activities and the vision or mission.
																	</p>
																</li>
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreMission}
													defaultValue={selectedApp?.scoreMission}
													setSelectedValue={(evt) => handleChange("scoreMission", evt.value)}
												/>
											</div>

											{/* Community Impact */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="orgImpact"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													How does your organization impact the local community?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.orgImpact}
													rows="4"
												/>
												<label htmlFor="scoreImpact" className="block text-md font-semibold text-gray-800 mb-2">
													Community Impact (1-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowImpactTooltip(true)}
													>
														?
													</button>
													{showImpactTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowImpactTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Community Impact Guide:</h4>
															<ul className="list-none text-gray-700 space-y-2">
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">0 - </span>
																	<p>
																		<strong>Scope:</strong>  The nonprofit's activities do not reach the community or serve only a very limited group.
																	</p>
																	<p>
																		<strong>Depth:</strong> No measurable changes in community outcomes or quality of life are observed.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">1 - </span>
																	<p>
																		<strong>Scope:</strong> The nonprofit's activities have a minimal reach, impacting only a small portion of the community.
																	</p>
																	<p>
																		<strong>Depth:</strong>  Measurable changes are limited in scale, with little lasting improvement in community outcomes or quality of life.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">2 - </span>
																	<p>
																		<strong>Scope:</strong>  The nonprofit's activities reach a moderate portion of the community, impacting multiple groups or areas.
																	</p>
																	<p>
																		<strong>Depth:</strong> The nonprofit's efforts lead to measurable changes in community outcomes or quality of life, though they may not be consistent across the entire community.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">3 - </span>
																	<p>
																		<strong>Scope:</strong> The nonprofit's activities have a broad reach, impacting a large portion of the community or addressing key community needs.
																	</p>
																	<p>
																		<strong>Depth:</strong> The nonprofit's efforts lead to significant and measurable changes in community outcomes, with noticeable improvements in quality of life.

																	</p>
																</li>
															</ul>
														</div>
													)}
												</label>
												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													value={selectedApp?.scoreImpact}
													placeholder={selectedApp?.scoreImpact}
													setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
												/>
											</div>

											{/* Location Usage */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="locationUsage"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													How are you planning to use our location?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.locationUsage}
													rows="4"
												/>
												<label htmlFor="scoreLocationUsage" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Usage Engagement Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowLocationUsageTooltip(true)}
													>
														?
													</button>
													{showLocationUsageTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowLocationUsageTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Usage Engagement Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "No plan to utilize the location."
																	},
																	{
																		score: 1,
																		text: "Coworking with a desire for community and connection. Mostly internal organization engagement."
																	},
																	{
																		score: 2,
																		text: "Coworking with a desire for community and connection with the hope of hosting meetings in our space."
																	},
																	{
																		score: 3,
																		text: "Desire for full engagement in coworking, community, meetings, and events. Bringing people into the space."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="flex items-start space-x-3 text-gray-800">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreLocationUsage}
													defaultValue={selectedApp?.scoreLocationUsage}
													setSelectedValue={(evt) => handleChange("scoreLocationUsage", evt.value)}
												/>
											</div>

											{/* Organization's Top Three Challenges */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="threeChallenges"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													What are your organization's top three challenges or barriers?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.threeChallenges}
													rows="4"
												/>
												<label htmlFor="scoreChallenges" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Challenge Removal Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowChallengeTooltip(true)}
													>
														?
													</button>
													{showChallengeTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowChallengeTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Challenge Removal Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "List none."
																	},
																	{
																		score: 1,
																		text: "List one out of three that could potentially be removed by the COhatch ecosystem."
																	},
																	{
																		score: 2,
																		text: "List two of three challenges that could potentially be removed by the COhatch ecosystem."
																	},
																	{
																		score: 3,
																		text: "List three out of three challenges that could potentially be removed by the COhatch ecosystem."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreChallenges}
													defaultValue={selectedApp?.scoreChallenges}
													setSelectedValue={(evt) => handleChange("scoreChallenges", evt.value)}
												/>
											</div>

											{/* Organization Funding */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="funding"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													How is your organization funded?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.funding}
													rows="4"
												/>
												<label htmlFor="scoreFunding" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Funding Plan Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowFundingTooltip(true)}
													>
														?
													</button>
													{showFundingTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowFundingTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Funding Plan Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "None"
																	},
																	{
																		score: 1,
																		text: "No clear plan, fundraiser only."
																	},
																	{
																		score: 2,
																		text: "Fundraising and partners."
																	},
																	{
																		score: 3,
																		text: "Sustainable plan."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreFunding}
													defaultValue={selectedApp?.scoreFunding}
													setSelectedValue={(evt) => handleChange("scoreFunding", evt.value)}
												/>
											</div>

											{/* Annual Events */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="annualEvents"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Does your organization have any annual events? If so, please tell us about your events.
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.annualEvents}
													rows="4"
												/>
												<label htmlFor="scoreEvents" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Events Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowEventsTooltip(true)}
													>
														?
													</button>
													{showEventsTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowEventsTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Events Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "None"
																	},
																	{
																		score: 1,
																		text: "Not currently but is interested."
																	},
																	{
																		score: 2,
																		text: "Currently has an annual event in other locations."
																	},
																	{
																		score: 3,
																		text: "Has a variety of events and COhatch is a possible location."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreEvents}
													defaultValue={selectedApp?.scoreEvents}
													setSelectedValue={(evt) => handleChange("scoreEvents", evt.value)}
												/>
											</div>

											{/* Volunteer Usage */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="volunteers"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Does your organization use volunteers? If so, tell us how?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring focus:ring-primary-300"
													defaultValue={selectedApp?.volunteers}
													rows="4"
												/>
												<label htmlFor="scoreVolunteers" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Volunteer Engagement Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowVolunteerTooltip(true)}
													>
														?
													</button>
													{showVolunteerTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowVolunteerTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Volunteer Engagement Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "None"
																	},
																	{
																		score: 1,
																		text: " Yes. One opportunity a year."
																	},
																	{
																		score: 2,
																		text: "One to two opportunities that could engage members."
																	},
																	{
																		score: 3,
																		text: "Multiple opportunities for member volunteer activation."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreVolunteers}
													defaultValue={selectedApp?.scoreVolunteers}
													setSelectedValue={(evt) => handleChange("scoreVolunteers", evt.value)}
												/>
											</div>

											{/* Overall Application Impact */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label htmlFor="scoreImpact" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Overall Application Impact Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowOverallImpactTooltip(true)}
													>
														?
													</button>
													{showOverallImpactTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowOverallImpactTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Impact Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-2">
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">3 - </span>
																	<p>
																		<strong>Impact:</strong> Applicant is unprecedentedly challenging established norms and approaches. Certain to generate insight and/or significant impact.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">2 - </span>
																	<p>
																		<strong>Impact:</strong> Applicant is challenging established norms and approaches in the community. Creating insight and significant tangible impact.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">1 - </span>
																	<p>
																		<strong>Impact:</strong> Applicant builds and is working on improving the community through new insight and tangible impact.
																	</p>
																</li>
																<li className="text-gray-800 font-normal">
																	<span className="text-gray-900">0 - </span>
																	<p>
																		<strong>Impact:</strong> Applicant relies exclusively on a solo approach and does not involve any community involvement or new insight.
																	</p>
																</li>
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreOverallAppImpact}
													defaultValue={selectedApp?.scoreOverallAppImpact}
													setSelectedValue={(evt) => handleChange("scoreOverallAppImpact", evt.value)}
												/>
											</div>

											{/* Total Score */}
											<div className="w-full bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="score"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Total Score (24 maximum)


												</label>
												<input
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													name="score"
													id="score"
													placeholder={
														(selectedApp?.scoreOverallAppImpact || 0) +
														(selectedApp?.scoreVolunteers || 0) +
														(selectedApp?.scoreEvents || 0) +
														(selectedApp?.scoreFunding || 0) +
														(selectedApp?.scoreChallenges || 0) +
														(selectedApp?.scoreLocationUsage || 0) +
														(selectedApp?.scoreImpact || 0) +
														(selectedApp?.scoreMission || 0)
													}
												/>
											</div>


											{/* # of Team Members Applying for Scholarship */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="teamMembersApplying"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													# of Team Members Applying for Scholarship
												</label>
												<input
													type="number"
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													placeholder={selectedApp?.orgAmount}
												/>

												{/* # of Team Members Awarded Scholarship */}

												<label
													htmlFor="awardedMembers"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													# of Team Members Awarded Scholarship (5 max)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowMembersAwardedTooltip(true)}
													>
														?
													</button>
													{showMembersAwardedTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowMembersAwardedTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Volunteer Engagement Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "0 - 5 points - DECLINE"
																	},
																	{
																		score: 1,
																		text: "6 - 8 points (1-2 memberships)"
																	},
																	{
																		score: 2,
																		text: "16 points - 9 points (up to 3 memberships)"
																	},
																	{
																		score: 3,
																		text: "17 - 24 points (up to 5 memberships)"
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>
												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													setSelectedValue={handleAnnualAward}
													options={orgAmountOPTIONS}
													placeholder={
														selectedApp?.awardedMember
															? selectedApp?.awardedMember
															: selectedApp?.scoreMemAwarded
													}
												/>
											</div>



											{/* Annual Award */}
											<div className="w-full bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="awardAmount"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Annual Award ($199 x member for 12 months)
												</label>
												<input
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													type="awardAmount"
													name="awardAmount"
													id="awardAmount"
													value={amount}
													placeholder={selectedApp?.annualAward}
												/>
											</div>
										</div>


									</>
								)}


								{selectedApp?.type === "boost" && (
									<>
										<div className="mt-6 mb-4 w-full space-y-6 bg-gray-50 p-6 rounded-lg shadow-md">

											{/* Business Video*/}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm mb-7">
												<label
													htmlFor="businessVideo"
													className="text-lg font-semibold text-gray-800 block mb-2"
												>
													Record a Business Video (3 minutes or less)
												</label>
												<div className="mt-1">
													{selectedApp?.businessVideo && selectedApp?.businessVideo !== "N/A" ? (
														<Link
															className="underline text-blue-600 hover:text-blue-800 transition"
															to={selectedApp.businessVideo}
															target="_blank"
														>
															{selectedApp.businessVideo}
														</Link>
													) : (
														<span className="text-gray-600">
															No business video available for this application.
														</span>
													)}
												</div>
												<label
													htmlFor="scoreBusinessVideo"
													className="block text-md font-semibold text-gray-800 mb-2 relative mt-4"
												>
													Business Video Score (0 OR 3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowBusinessVideoTooltip(true)}
													>
														?
													</button>
													{showBusinessVideoTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowBusinessVideoTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">
																Business Video Score Guide:
															</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "No video submitted.",
																	},
																	{
																		score: 3,
																		text: "Thorough video presenting a clear narrative of the business.",
																	},
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS.filter(
														(option) => option.value === 0 || option.value === 3
													)} // Filter to show only 0 and 3
													placeholder={
														selectedApp?.scoreBusinessVideo != null
															? selectedApp.scoreBusinessVideo
															: "Select a score"
													}
													defaultValue={
														selectedApp?.scoreBusinessVideo != null
															? selectedApp.scoreBusinessVideo
															: undefined
													}
													setSelectedValue={(evt) => handleChange("scoreBusinessVideo", evt.value)}
												/>
											</div>


											<h2 className="text-lg font-semibold text-gray-800 mb-4">Business Overview</h2>

											{/* Tell Us About Your Business Plan */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="businessPlan"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Tell Us About Your Business Plan:
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 text-gray-700 bg-gray-100"
													defaultValue={selectedApp?.businessPlan}
													rows="4"
												/>

												<label
													htmlFor="scoreBusinessPlan"
													className="block text-md font-semibold text-gray-800 mb-2 relative"
												>
													Business Plan Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowBusinessPlanTooltip(true)}
													>
														?
													</button>
													{showBusinessPlanTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowBusinessPlanTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Business Plan Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "Very little or no information provided about the business; lacks clarity and purpose."
																	},
																	{
																		score: 1,
																		text: "Partial or vague description of the business, missing key elements such as goals or target audience."
																	},
																	{
																		score: 2,
																		text: "Good description, with most essential elements (goals, target market) covered, but lacking some depth or clarity."
																	},
																	{
																		score: 3,
																		text: "Thorough description of the business, including clear goals, target market, value proposition, and operational plan."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreBusinessPlan}
													defaultValue={selectedApp?.scoreBusinessPlan}
													setSelectedValue={(evt) => handleChange("scoreBusinessPlan", evt.value)}
												/>
											</div>


											{/* Location Usage */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="locationUsage"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													How are you planning to use our location?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													defaultValue={selectedApp?.locationUsage}
													rows="4"
												/>

												<label htmlFor="scoreLocationUsage" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Usage Engagement Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowLocationUsageTooltip(true)}
													>
														?
													</button>
													{showLocationUsageTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowLocationUsageTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Usage Engagement Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "Little or no information on how the location will be utilized; unclear connection to business needs. No plan."
																	},
																	{
																		score: 1,
																		text: "Basic idea of how the location will be used, but vague on the details or how it will benefit the business. Coworking with a desire for community and connection. Mostly internal organization engagement."
																	},
																	{
																		score: 2,
																		text: "Good explanation of location use, but may lack detail on the specific impact or benefits. Coworking with a desire for community and connection with the hope of hosting meetings in our space."
																	},
																	{
																		score: 3,
																		text: "Desire for full engagement in coworking, community, meetings, and events. Bring people into the space. Clearly outlines specific, strategic ways the location will be used to grow or support the business, with tangible benefits."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="flex items-start space-x-3 text-gray-800">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreLocationUsage}
													defaultValue={selectedApp?.scoreLocationUsage}
													setSelectedValue={(evt) => handleChange("scoreLocationUsage", evt.value)}
												/>
											</div>


											{/* Funding */}
											{/* <div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="fundingStrategy"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													What Is Your Funding Strategy:
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100 text-gray-700"
													defaultValue={selectedApp?.fundingStrategy}
													rows="4"
												/>

												<label
													htmlFor="scoreFundingStrategy"
													className="block text-md font-semibold text-gray-800 mb-2 relative mt-2"
												>
													Funding Strategy Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowFundingStrategyTooltip(true)}
													>
														?
													</button>
													{showFundingStrategyTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowFundingStrategyTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Funding Strategy Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "Very little or no information provided about the funding strategy; lacks clarity and purpose."
																	},
																	{
																		score: 1,
																		text: "Partial or vague description of the funding strategy, missing key elements such as sources of funding."
																	},
																	{
																		score: 2,
																		text: "Good description, with most essential elements (sources of funding) covered, but lacking some depth or clarity."
																	},
																	{
																		score: 3,
																		text: "Thorough description of the funding strategy, including clear sources of funding, budget outlines, and financial projections."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="flex items-start space-x-3 text-gray-800">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreFundingStrategy}
													defaultValue={selectedApp?.scoreFundingStrategy}
													setSelectedValue={(evt) => handleChange("scoreFundingStrategy", evt.value)}
												/>
											</div> */}

											{/* Breakeven Point */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="breakevenPoint"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													When Do You Project Your Breakeven Point:
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 text-gray-700 bg-gray-100"
													defaultValue={selectedApp?.breakevenPoint}
													rows="4"
												/>

												<label
													htmlFor="scoreBreakevenPoint"
													className="block text-md font-semibold text-gray-800 mb-2 relative"
												>
													Breakeven Point Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowBreakevenPointTooltip(true)}
													>
														?
													</button>
													{showBreakevenPointTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowBreakevenPointTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Breakeven Point Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "None. Little to no information on funding or breakeven; plan appears unrealistic."
																	},
																	{
																		score: 1,
																		text: "Some information on funding or breakeven, but lacks detail or confidence."
																	},
																	{
																		score: 2,
																		text: "Clear funding sources, but breakeven timeline may be vague or overly optimistic."
																	},
																	{
																		score: 3,
																		text: "Sustainable Plan. Detailed and realistic explanation of funding sources and a well-justified breakeven timeline."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreBreakevenPoint}
													defaultValue={selectedApp?.scoreBreakevenPoint}
													setSelectedValue={(evt) => handleChange("scoreBreakevenPoint", evt.value)}
												/>
											</div>

											{/* Organization's Top Three Challenges */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="threeChallenges"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													What are your organization's top three challenges or barriers?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 text-gray-700 bg-gray-100"
													defaultValue={selectedApp?.threeChallenges}
													rows="4"
												/>

												<label
													htmlFor="scoreChallenges"
													className="block text-md font-semibold text-gray-800 mb-2 relative"
												>
													Challenge Removal Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowChallengeTooltip(true)}
													>
														?
													</button>
													{showChallengeTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowChallengeTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Challenge Removal Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "List none. No clear challenges identified; lacks insight into potential obstacles."
																	},
																	{
																		score: 1,
																		text: "List one out of three that could potentially be removed by the COhatch ecosystem. Challenges identified, with limited or unclear explanation of their significance."
																	},
																	{
																		score: 2,
																		text: "List two of three that could potentially be removed by the COhatch ecosystem. Some challenges identified, but not all seem crucial or well-explained."
																	},
																	{
																		score: 3,
																		text: "Clearly identifies three realistic and critical challenges, with some explanation of how COhatch could potentially address them."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreChallenges}
													defaultValue={selectedApp?.scoreChallenges}
													setSelectedValue={(evt) => handleChange("scoreChallenges", evt.value)}
												/>
											</div>


											{/* No. of emplyees */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="planHire"
													className="text-md font-semibold text-gray-800 block mb-2"
												>
													How Many Employees Do You Have? How Many Do You Plan To Hire In The Next 12/24/36 Months?
												</label>
												<textarea
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 text-gray-700 bg-gray-100"
													defaultValue={selectedApp?.planHire}
													rows="4"
												/>

												<label
													htmlFor="scorePlanHire"
													className="block text-md font-semibold text-gray-800 mb-2 relative mt-2"
												>
													Number of Employee Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowPlanHireTooltip(true)}
													>
														?
													</button>
													{showPlanHireTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowPlanHireTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Number of Employee Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "No clear information provided on employees or hiring plans."
																	},
																	{
																		score: 1,
																		text: "Rough or unclear estimates on employee numbers or hiring plans."
																	},
																	{
																		score: 2,
																		text: "Employee numbers and growth plan provided, but lacking detail or long-term projections."
																	},
																	{
																		score: 3,
																		text: "Specific current employee numbers with clear, realistic growth projections over the next 36 months."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scorePlanHire}
													defaultValue={selectedApp?.scorePlanHire}
													setSelectedValue={(evt) => handleChange("scorePlanHire", evt.value)}
												/>
											</div>


											{/* Overall Application Impact */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label htmlFor="scoreImpact" className="block text-md font-semibold text-gray-800 mb-2 relative">
													Overall Application Impact Score (0-3 Scale)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowOverallImpactTooltip(true)}
													>
														?
													</button>
													{showOverallImpactTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowOverallImpactTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Impact Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 3,
																		text: "The entire application, including the video, presents a strong, cohesive narrative of the business with clear goals and impact."
																	},
																	{
																		score: 2,
																		text: "Overall solid application with minor gaps in clarity or cohesiveness."
																	},
																	{
																		score: 1,
																		text: "Some parts of the application are unclear, incomplete, or inconsistent."
																	},
																	{
																		score: 0,
																		text: "The application is disorganized or lacks necessary information to form a cohesive picture of the business."
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>

												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={scorecardOPTIONS}
													placeholder={selectedApp?.scoreOverallAppImpact}
													defaultValue={selectedApp?.scoreOverallAppImpact}
													setSelectedValue={(evt) => handleChange("scoreImpact", evt.value)}
												/>
											</div>


											{/* Total Score */}
											<div className="w-full bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="score"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													Total Score (21 maximum)
												</label>
												<input
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													name="score"
													id="score"
													placeholder={
														(selectedApp?.scoreBusinessVideo || 0) +
														(selectedApp?.scoreBusinessPlan || 0) +
														(selectedApp?.scoreLocationUsage || 0) +
														(selectedApp?.scoreBreakevenPoint || 0) +
														(selectedApp?.scoreChallenges || 0) +
														(selectedApp?.scorePlanHire || 0) +
														(selectedApp?.scoreOverallAppImpact || 0)
													}
												/>
											</div>

											{/* # of Team Members Applying for Scholarship */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="teamMembersApplying"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													# of Team Members Applying for Scholarship
												</label>
												<input
													type="number"
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100"
													placeholder={selectedApp?.orgAmount}
												/>

												{/* # of Team Members Awarded Scholarship */}

												<label
													htmlFor="awardedMembers"
													className="block text-md font-semibold text-gray-800 mb-2"
												>
													# of Team Members Awarded Scholarship (5 max)
													<button
														type="button"
														className="ml-2 text-red-600 font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600"
														onClick={() => setShowMembersAwardedTooltip(true)}
													>
														?
													</button>
													{showMembersAwardedTooltip && (
														<div className="absolute left-0 mt-2 w-96 bg-white p-4 border border-gray-300 rounded-lg shadow-lg z-50 animate-fadeIn max-h-60 overflow-y-auto">
															<button
																type="button"
																className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
																onClick={() => setShowMembersAwardedTooltip(false)}
															>
																X
															</button>
															<h4 className="font-semibold mb-2 text-gray-900">Volunteer Engagement Score Guide:</h4>
															<ul className="list-none text-gray-700 space-y-4">
																{[
																	{
																		score: 0,
																		text: "0 - 4 points - DECLINE"
																	},
																	{
																		score: 1,
																		text: "5 - 7 points (1-2 memberships)"
																	},
																	{
																		score: 2,
																		text: "15 points - 8 points (up to 3 memberships)"
																	},
																	{
																		score: 3,
																		text: "16 - 21 points (up to 5 memberships)"
																	}
																].map(({ score, text }) => (
																	<li key={score} className="text-gray-800 flex items-start space-x-3">
																		<div className="bg-gray-200 text-success-800 font-semibold h-8 w-8 flex items-center justify-center rounded-full">
																			{score}
																		</div>
																		<p className="text-md font-normal">{text}</p>
																	</li>
																))}
															</ul>
														</div>
													)}
												</label>
												<SelectInput
													className="block w-full mt-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													setSelectedValue={handleAnnualAward}
													options={orgAmountOPTIONS}
													placeholder={
														selectedApp?.awardedMember
															? selectedApp?.awardedMember
															: selectedApp?.scoreMemAwarded
													}
												/>
											</div>



											{/* Annual Award */}
											<div className="w-full relative bg-white rounded-lg p-4 shadow-sm">
												<label
													htmlFor="awardAmount"
													className="text-md font-semibold text-gray-800 block mb-2"
												>
													Annual Award ($109 x member for 12 months)
												</label>
												<input
													disabled
													className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-100 text-gray-700"
													type="text"
													name="awardAmount"
													id="awardAmount"
													value={amount}
													placeholder={selectedApp?.annualAward}
												/>
											</div>

										</div>
									</>
								)}
							</div>
							<div className="w-full relative bg-white rounded-lg p-4 shadow-sm mt-10">
								<h1 className="mb-8 font-bold text-xl">Status</h1>

								<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
									{userState.role[0] === "COMMUNITY_MANAGER" || userState.role[0] === "MASTER_APPROVAL" ? (
										<div className="w-full">
											<label
												htmlFor="cmStatus"
												className="block font-medium text-gray-800 mb-2"
											>
												Community Manager Approval Status
											</label>
											<div className="mt-1">
												{selectedApp?.cmStatus === "pending" ? (
													<SelectInput
														className="capitalize appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														placeholder={String(selectedApp?.cmStatus).toUpperCase()}
														options={statusOPTIONS}
														setSelectedValue={(evt) => handleChange("cmStatus", evt.value)}
													/>
												) : (
													<div className="mt-1">
														<div className="flex-shrink-0 text-sm text-gray-600 px-4 py-3 border border-gray-600 rounded-md">
															{String(selectedApp?.cmStatus).toUpperCase()}
														</div>
													</div>
												)}
											</div>
										</div>
									) : (
										<div className="w-full">
											<label
												htmlFor="cmStatus"
												className="block font-medium text-gray-800 mb-2"
											>
												Community Manager Approval Status
											</label>
											<div className="mt-1">
												<div className="flex-shrink-0 text-sm text-gray-600 px-4 py-3 border border-gray-600 rounded-md">
													{String(selectedApp?.cmStatus).toUpperCase()}
												</div>
											</div>
										</div>
									)}

									{userState.role[0] === "MARKET_LEADER" || userState.role[1] === "MARKET_LEADER" || userState.role[0] === "MASTER_APPROVAL" ? (
										<div className="w-full">
											<label
												htmlFor="mlStatus"
												className="block font-medium text-gray-800 mb-2"
											>
												Market Leader Approval Status
											</label>
											<div className="mt-1">
												{selectedApp?.mlStatus === "pending" && selectedApp?.cmStatus !== "pending" ? (
													<SelectInput
														className="capitalize appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={statusOPTIONS}
														setSelectedValue={(evt) => handleChange("mlStatus", evt.value)}
														placeholder={String(selectedApp?.mlStatus).toUpperCase()}
													/>
												) : (
													<div className="mt-1">
														<div className="flex-shrink-0 text-sm text-gray-600 px-4 py-3 border border-gray-600 rounded-md">
															{String(selectedApp?.mlStatus).toUpperCase()}
														</div>
													</div>
												)}
											</div>
										</div>
									) : (
										<div className="w-full">
											<label
												htmlFor="mlStatus"
												className="block font-medium text-gray-800 mb-2"
											>
												Market Leader Approval Status
											</label>
											<div className="mt-1">
												<div className="flex-shrink-0 text-sm text-gray-600 px-4 py-3 border border-gray-600 rounded-md">
													{String(selectedApp?.mlStatus).toUpperCase()}
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0 mt-4">
									{selectedApp?.type === "give" && (
										<div className="w-full">
											<label
												htmlFor="status501"
												className="block font-medium text-gray-800 mb-2"
											>
												501C3 Approved
											</label>
											<div className="mt-1">
												<SelectInput
													className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={statusOPTIONS}
													placeholder={String(selectedApp?.status501).toUpperCase()}
													setSelectedValue={(evt) => handleChange("status501", evt.value)}
												/>
											</div>
										</div>
									)}

									<div className="w-full">
										<label
											htmlFor="officeRnd"
											className="block font-medium text-gray-800 mb-2"
										>
											OfficeRnD Status
										</label>
										<div className="mt-1">
											<SelectInput
												className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												options={statusOPTIONS}
												setSelectedValue={(evt) => handleChange("officeRnd", evt.value)}
												placeholder={String(selectedApp?.officeRnd).toUpperCase()}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full flex justify-end mt-6">
								<button
									className="inline-flex items-center justify-center rounded-lg border border-transparent bg-primary-600 py-3 px-6 text-sm font-semibold text-white shadow-sm transition duration-150 ease-in-out hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
									type="button"
									onClick={() => {
										handleUpdate(payload);
									}}
								>
									Save Changes
								</button>
							</div>

						</div>
					)}

					{selectedTab === "Application" && (
						<div className="mt-10 col-span-4 ">
							{selectedApp && (
								<ApplicationFile applicationId={selectedApp._id} />
							)}
						</div>
					)}

					{/* Info Overview Section */}
					<div className="mt-2 col-span-2">
						<div className="rounded-md p-3 bg-white shadow-lg">
							{selectedApp?.type === "boost" &&
								selectedApp?.jobRole != null ? (
								<div className="mb-3">
									<h1 className="font-bold ">Job Role</h1>
									<p className="text-gray-600">{selectedApp?.jobRole}</p>
								</div>
							) : null}
							<div className="mb-3">
								<h1 className="font-bold">Location & Market</h1>
								<p className="capitalize text-gray-600">
									{selectedApp?.coLocation} | {selectedApp?.market}
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Email</h1>
								<p className="truncate hover:text-clip text-gray-600">
									{selectedApp?.email}
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Phone Number</h1>
								<p className="text-gray-600">{selectedApp?.phoneNumber}</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Website</h1>
								<p className="text-gray-600">{selectedApp?.website}</p>
							</div>
							{selectedApp?.type === "boost" &&
								selectedApp?.dateFounded != null ? (
								<div className="mb-3">
									<h1 className="font-bold ">Date Founded</h1>
									<p className="text-gray-600">{selectedApp?.dateFounded}</p>
								</div>
							) : null}

							<div className="mb-3">
								<h1 className="font-bold ">Submission Date</h1>
								<p className="text-gray-600">
									<time>{format(new Date(createdAt), "MMMM do, yyyy")}</time>
								</p>
							</div>

							{selectedApp?.onboardingDate != null ? (
								<div className="mb-3">
									<h1 className="font-bold">Onboarding Date</h1>
									<time className="text-gray-600">
										{(() => {
											// Create a Date object from the UTC date string
											const date = new Date(selectedApp?.onboardingDate);

											// Get the local time zone offset in minutes
											const timezoneOffset = date.getTimezoneOffset();

											// Set the date to midnight UTC (so it doesn’t adjust)
											const utcDate = new Date(date.getTime() + timezoneOffset * 60000);

											// Return the formatted local date
											return utcDate.toLocaleDateString('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											});
										})()}
									</time>
								</div>
							) : null}

							{selectedApp?.onboardingDate != null ? (
								<div className="mb-3">
									<h1 className="font-bold">Renewal Date</h1>
									<p className="text-gray-600">
										<time>
											{format(new Date(newDate), "MMMM do, yyyy")}
										</time>
									</p>
								</div>
							) : null}

							<div className="mb-3">
								<h1 className="font-bold ">Sponsorship</h1>
								<p className="text-gray-600">
									{selectedApp?.orgSponsored === "no"
										? "Not Sponsored"
										: `Sponsored by: ${selectedApp?.orgSponsored}`}
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Applicant Contacted ?</h1>
								<button
									className={`bg-${isContacted ? "green" : "blue"
										}-500 hover:bg-${isContacted ? "green" : "blue"
										}-700 text-white font-bold py-2 px-4 border border-2 rounded focus:outline-none focus:shadow-outline`}
									style={{ color: "black" }} // Set contrasting text color
									onClick={handleClick}
								>
									{selectedApp?.applicantContacted
										? selectedApp?.applicantContacted
										: "No"}
								</button>
							</div>
							{/* <div className="mb-3">
								<h1 className="font-bold">Ban Application</h1>
								<button
									onClick={handleBanApplication}
									className="cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white bg-red-500"
								>
									{selectedApp?.appBanned ? "Unban" : "Ban"}
								</button>
							</div> */}

							<div className="mb-3">
								<button
									className="cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white  bg-success-800 "
									size="md"
									onClick={() => setShowApplicantModal(true)}
								>
									Edit Application
								</button>
								{selectedApp && (
									<EditApplicationModal
										open={showApplicantModal}
										onClose={() => setShowApplicantModal(false)}
										app={selectedApp}
										handleSubmit={handleSubmit}
									/>
								)}
							</div>
							{isModalOpen && (
								<Modal>
									<div className="fixed z-10 inset-0 overflow-y-auto">
										<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
											{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
											<span
												className="hidden sm:inline-block sm:align-middle sm:h-screen"
												aria-hidden="true"
											>
												&#8203;
											</span>
											<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
												<div className="w-full flex justify-between items-center">
													<div>
														<p>Are you sure you want to update the status?</p>
														<button
															className="mb-2 ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
															onClick={handleConfirm}
														>
															Yes
														</button>
														<ButtonPrimary
															className="text-gray-700 block px-4 py-2 text-sm"
															text="Cancel"
															action={handleCancel}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Modal>
							)}
						</div>
					</div>
				</div>
			</>


			{application?.type === "Community Partner" && (
				<CommunityPartnerProfile
					//handleChange={handleChange}
					selectedApp={application}
				/>
			)}
		</>
	);
}

export default ApplicantProfile;
